body {
  margin: 0;
  font-family: "Euclid Square",-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  max-width: 100%;
  overflow-x: hidden;
  text-decoration: none;
}

p {
  padding: 0;
  margin: 0;
}

h1,h2,h3,h4,h5,h6 {
  margin: 0px
}
body {
  height: 100vh;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Euclid Square';
  src: local('Euclid Square Bold'), local('EuclidSquare-Bold'),
  url('/public/fonts/EuclidSquare-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Euclid Square';
  src: local('Euclid Square Regular'), local('EuclidSquare-Regular'),
  url('/public/fonts/EuclidSquare-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Euclid Square';
  src: local('Euclid Square SemiBold'), local('EuclidSquare-SemiBold'),
  url('/public/fonts/EuclidSquare-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Euclid Square';
  src: local('Euclid Square Light Italic'), local('EuclidSquare-LightItalic'),
  url('/public/fonts/EuclidSquare-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Euclid Square';
  src: local('Euclid Square Medium Italic'), local('EuclidSquare-MediumItalic'),
  url('/public/fonts/EuclidSquare-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Euclid Square';
  src: local('Euclid Square Medium'), local('EuclidSquare-Medium'),
  url('/public/fonts/EuclidSquare-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Euclid Square';
  src: local('Euclid Square Italic'), local('EuclidSquare-Italic'),
  url('/public/fonts/EuclidSquare-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Euclid Square';
  src: local('Euclid Square Light'), local('EuclidSquare-Light'),
  url('/public/fonts/EuclidSquare-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Euclid Square';
  src: local('Euclid Square SemiBold Italic'), local('EuclidSquare-SemiBoldItalic'),
  url('/public/fonts/EuclidSquare-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Euclid Square';
  src: local('Euclid Square Bold Italic'), local('EuclidSquare-BoldItalic'),
  url('/public/fonts/EuclidSquare-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

